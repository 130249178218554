<template>
  <div class="create-upsell-item-page">
    <BackTitle class="mb-40" confirm-click @click="goBack">
      Create upsell merchandise
    </BackTitle>
    <UpsellItemForm
      :value="upsellItem"
      :is-submitting="isSubmitting"
      data-v-step="12"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import UpsellItemForm from "@/components/upsell-items/UpsellItemForm";
import { NEW_UPSELL_ITEM_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";
export default {
  name: "CreateUpsellItemPage",
  components: { UpsellItemForm, BackTitle },
  props: {
    duplicate: {
      type: Object,
    },
  },
  data() {
    return {
      upsellItem: {
        ...NEW_UPSELL_ITEM_MOCK,
      },
      isSubmitting: false,
    };
  },
  created() {
    if (this.duplicate) {
      this.upsellItem = {
        ...this.duplicate,
      };
    }
  },
  methods: {
    ...mapActions({
      createUpsellItem: "upsellItems/createUpsellItem",
    }),
    goBack() {
      this.$router.push({
        name: "UpsellItems",
      });
    },
    async handleSubmit(data) {
      try {
        this.isSubmitting = true;
        await this.createUpsellItem(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-upsell-item-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
